@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

/*Navbar CSS Styling */
.nav-bar {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 20%;
  backdrop-filter: blur(5px); /* Blur effect */
  width: 100%;
  background-color: #4e393957;
}

.nav-heading {
  display: flex;
  flex-direction: column;
  font-size: 8px;
  gap: 50%;
  color: rgba(100, 62, 62, 0.89);
}

.nav-bag {
  position: relative;
  top: 10%;
  padding-left: 10%;
  color: white;
}

@media only screen and (min-width: 600px) {
  .nav-bar {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 20%;
    backdrop-filter: blur(5px); /* Blur effect */
    background-color: #4e393957;

  }

  .nav-heading {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    gap: 50%;
    font-style: italic;
    color: white;
  }

  .nav-bag {
    position: relative;
    top: 10%;
    padding-left: 10%;
    color: white;
  }
}

@media only screen and (min-width: 1024px) {
  .nav-bar {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 20%;
    backdrop-filter: blur(5px); /* Blur effect */
    background-color: #4e393957;
  }

  .nav-heading {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    gap: 20%;
    font-style: italic;
    color: white;
  }

  .nav-bag {
    position: relative;
    top: 10%;
    padding-left: 10%;
    color: white;
  }
}

/*Home-container */

.home-container {
  align-items: center;
  max-width: 100%;
  text-align: center;
  backdrop-filter: blur(5px); /* Blur effect */
  background-color: #4e393957;

}

.home-container img {
  max-width: 100%;
}

.home-container h2 {
  font-size: 20px;
  color: rgba(63, 37, 37, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}

/*Home-container @media only screen and (min-width: 600px) */

@media only screen and (min-width: 600px) {
  .home-container {
    align-items: center;
    max-width: 100%;
    text-align: center;
    backdrop-filter: blur(5px); /* Blur effect */
    background-color: #4e393957;

  }

  .home-container img {
    max-width: 100%;
  }

  .home-container h2 {
    font-size: 20px;
    color: rgba(63, 37, 37, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }
}

/*Home-container @media only screen and (min-width: 1024px) */

@media only screen and (min-width: 1024px) {
  .home-container {
    align-items: center;
    max-width: 100%;
    text-align: center;
    backdrop-filter: blur(5px); /* Blur effect */
    background-color: #4e393957;

  }

  .home-container img {
    max-width: 100%;
  }

  .home-container h2 {
    font-size: 20px;
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }
}

.hero-image {
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex; /* Make.hero-image a flex container */
  align-items: center; /* Center child elements vertically */
  backdrop-filter: blur(5px); /* Blur effect */
  background-color: #4e393957;
}

.hero-image img {
  max-width: 100%;
  height: auto; /* Adjusted from 100% to maintain aspect ratio */
}

.hero-text {
  width: 100%;
  position: relative; /* Ensure the hero-text is positioned correctly */
}

.hero-text span {
  font-size: 20px;
  color: rgb(204, 200, 165);
  top: 100%; /* Start the text off-screen to the right */
}

@media only screen and (min-width: 600px) {
  .hero-image {
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex; /* Make.hero-image a flex container */
    justify-content: center; /* Center child elements horizontally */
    align-items: center; /* Center child elements vertically */
    backdrop-filter: blur(5px); /* Blur effect */
  }

  .hero-image img {
    max-width: 50%;
    height: auto; /* Adjusted from 100% to maintain aspect ratio */

  }

  .hero-text {
    width: 100%;
    position: relative; /* Ensure the hero-text is positioned correctly */
  }

  .hero-text span {
    font-size: 50px;
    color: rgb(204, 200, 165);
    top: 100%; /* Start the text off-screen to the right */
  }
}

@media only screen and (min-width: 1024px) {
  .hero-image {
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex; /* Make.hero-image a flex container */
    justify-content: center; /* Center child elements horizontally */
    align-items: center; /* Center child elements vertically */
    backdrop-filter: blur(5px); /* Blur effect */
  }

  .hero-image img {
    max-width: 50%;
    height: auto; /* Adjusted from 100% to maintain aspect ratio */

  }

  .hero-text {
    width: 100%;
    position: relative; /* Ensure the hero-text is positioned correctly */
  }

  .hero-text span {
    font-size: 80px;
    color: rgb(204, 200, 165);
    top: 100%; /* Start the text off-screen to the right */
  }
}

.slider-container {
  max-width: 250px; /* Set the max width of the slider */
  margin: 0 auto; /* Center the slider */
}

.slick-slide img {
  display: block;
}

.product-image {
  width: 100%; /* Reduce the width to half */
  height: auto; /* Maintain the aspect ratio */
  display: block;
  margin: 0 auto; /* Center the image */
}

.product-overlay {
  position: relative;
  text-align: center; /* Center the text */
}

.product-details {
  text-align: center;
}

.products button {
  background-color: #5c494988;
  border-radius: 15px;
  border: 2px solid #752837;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}

.products button:hover {
  background-color: #cc0d269f;
  color: white;
}

.details {
  font-size: 15px;
  color: rgba(59, 11, 192, 0.562);
}

.slick-next,
.slick-prev {
  width: 50px; /* Increase the width */
  height: 50px; /* Increase the height */
  z-index: 1; /* Ensure arrows are on top */
}

.slick-prev:before,
.slick-next:before {
  font-size: 50px;
  color: black;
}

.custom-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.custom-dots li {
  margin: 0 5px;
}

.custom-dots li button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.custom-dots li.slick-active button {
  background-color: #ff0d0d;
}

.hero-image img {
  width: 100%;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 48px;
}

.product-image {
  width: 50%; /* Reduce the width to half */
  height: auto; /* Maintain the aspect ratio */
  display: block;
  margin: 0 auto; /* Center the image */
}

.product-overlay {
  position: relative;
  width: 300px;
  text-align: center; /* Center the text */
}

.product-details {
  text-align: center;
}

.products button {
  background-color: #5c494988;
  border-radius: 15px;
  border: 2px solid #752837;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}

.products button:hover {
  background-color: #cc0d269f;
  color: white;
}

.details {
  font-size: 15px;
  color: rgba(59, 11, 192, 0.562);
}

.products > div {
  position: relative; /* Set position to relative */
}

.products img {
  width: 100%; /* Ensure the image takes up the full width of its parent */
  display: flex;
  border-radius: 10px / 30px;
}

.products-text {
  position: absolute; /* Position the text absolutely */
  top: 50%; /* Align to the vertical center */
  left: 50%; /* Align to the horizontal center */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust position to center both vertically and horizontally */
  text-align: center; /* Center-align text */
  color: rgb(15, 3, 3);
  font-size: 20px;
}

@media only screen and (min-width: 600px) {
  .slider-container {
    max-width: 900px; /* Set the max width of the slider */
    margin: 0 auto; /* Center the slider */
  }

  .slick-slide img {
    display: block;
  }

  .product-image {
    width: 100%; /* Reduce the width to half */
    height: auto; /* Maintain the aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image */
  }

  .product-overlay {
    position: relative;
    text-align: center; /* Center the text */
  }

  .product-details {
    text-align: center;
  }

  .products button {
    background-color: #5c494988;
    border-radius: 15px;
    border: 2px solid #752837;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
  }

  .products button:hover {
    background-color: #cc0d269f;
    color: white;
  }

  .details {
    font-size: 15px;
    color: rgba(59, 11, 192, 0.562);
  }

  .slick-next,
  .slick-prev {
    width: 50px; /* Increase the width */
    height: 50px; /* Increase the height */
    z-index: 1; /* Ensure arrows are on top */
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 50px;
    color: black;
  }

  .custom-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .custom-dots li {
    margin: 0 5px;
  }

  .custom-dots li button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  .custom-dots li.slick-active button {
    background-color: #ff0d0d;
  }

  .hero-image img {
    width: 100%;
  }

  .hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 48px;
  }

  .product-image {
    width: 50%; /* Reduce the width to half */
    height: auto; /* Maintain the aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image */
  }

  .product-overlay {
    position: relative;
    width: 300px;
    text-align: center; /* Center the text */
  }

  .product-details {
    text-align: center;
  }

  .products button {
    background-color: #5c494988;
    border-radius: 15px;
    border: 2px solid #752837;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
  }

  .products button:hover {
    background-color: #cc0d269f;
    color: white;
  }

  .details {
    font-size: 15px;
    color: rgba(59, 11, 192, 0.562);
  }
}

/*Product Representation as 1024px Desktop view*/

@media only screen and (min-width: 1024px) {
  .slider-container {
    max-width: 900px; /* Set the max width of the slider */
    margin: 0 auto; /* Center the slider */
  }

  .slick-slide img {
    display: block;
  }

  .product-image {
    width: 100%; /* Reduce the width to half */
    height: auto; /* Maintain the aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image */
  }

  .product-overlay {
    position: relative;
    text-align: center; /* Center the text */
  }

  .product-details {
    text-align: center;
  }

  .products button {
    background-color: #5c494988;
    border-radius: 15px;
    border: 2px solid #752837;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
  }

  .products button:hover {
    background-color: #cc0d269f;
    color: white;
  }

  .details {
    font-size: 15px;
    color: rgba(59, 11, 192, 0.562);
  }

  .slick-next,
  .slick-prev {
    width: 50px; /* Increase the width */
    height: 50px; /* Increase the height */
    z-index: 1; /* Ensure arrows are on top */
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 50px;
    color: black;
  }

  .custom-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .custom-dots li {
    margin: 0 5px;
  }

  .custom-dots li button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  .custom-dots li.slick-active button {
    background-color: #ff0d0d;
  }

  .hero-image img {
    width: 100%;
  }

  .hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 48px;
  }

  .product-image {
    width: 50%; /* Reduce the width to half */
    height: auto; /* Maintain the aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image */
  }

  .product-overlay {
    position: relative;
    width: 300px;
    text-align: center; /* Center the text */
  }

  .product-details {
    text-align: center;
  }

  .products button {
    background-color: #5c494988;
    border-radius: 15px;
    border: 2px solid #752837;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
  }

  .products button:hover {
    background-color: #cc0d269f;
    color: white;
  }

  .details {
    font-size: 15px;
    color: rgba(59, 11, 192, 0.562);
  }
}
/* Responsive design adjustments */

.footer {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100%;
  backdrop-filter: blur(10px); /* Blur effect */
  background-color: #4e393957;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--dark);
  position: relative;
}

input {
  padding: 0.75rem 0;
  border: none;
  background: none;
  font-weight: 500;
  transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 0;
  width: 100%;
  font-size: 1.05rem;
  font-weight: bolder;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: var(--dark);
}

button {
  appearance: none;
  border: 0;
  background: transparent;
}

.footer_inner {
  background: var(--light);
  backdrop-filter: blur(50px);
  border: 0.1px solid rgba(233, 232, 232, 0.208);
  border-radius: 5px;
  padding: 2rem;
  margin: 1rem 0;
}

.layout {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
}

.layout_item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.w-50 {
  width: 100%;
}

.w-25 {
  width: 85%;
}

svg {
  margin: 0.5rem;
}

.c-2 {
  margin-top: 2.5rem;
}

.newsletter_title {
  font-family: "Noto Serif Display", serif;
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;
}

.c-nav-tool_title {
  font-size: 1.2rem;
  font-weight: bold;
}

.c-nav-tool_list {
  list-style-type: none;
  padding: 0;
}

.c-nav-tool_list li {
  margin-bottom: 0.5rem;
}

.c-nav-tool_list a.c-link {
  text-decoration: none;
  color: var(--dark);
  font-weight: 500;
  transition: 0.3s ease-in;
  border-bottom: 1px solid transparent;
  display: inline-flex;
}

.c-nav-tool_list a.c-link:hover {
  border-bottom: 1px solid var(--dark);
}

.social-icons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  opacity: 70%;
  transition: opacity 1s ease-in-out;
  font-size: 12px;
  color: #b61a1a;
}

.footer_section_links {
  justify-content: center;
  color: rgba(126, 14, 14, 0.699);
  font-size: 12px;
}

.footer_copyright {
  color: var(--light);
  text-align: center;
  padding: 1rem 0;
}

/* Footer 600px Responsive design adjustments */
@media only screen and (min-width: 600px) {
  .footer {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    backdrop-filter: blur(10px); /* Blur effect */
  }

  .container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--dark);
    position: relative;
  }

  input {
    padding: 0.75rem 0;
    border: none;
    background: none;
    font-weight: 500;
    transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 0;
    width: 100%;
    font-size: 1.05rem;
    font-weight: bolder;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: var(--dark);
  }

  button {
    appearance: none;
    border: 0;
    background: transparent;
  }

  .footer_inner {
    background: var(--light);
    backdrop-filter: blur(50px);
    border: 0.1px solid rgba(233, 232, 232, 0.208);
    border-radius: 5px;
    padding: 2rem;
    margin: 1rem 0;
  }

  .layout {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 2rem;
  }

  .layout_item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .w-50 {
    width: 50%;
  }

  .w-25 {
    width: 25%;
  }

  svg {
    margin: 0.5rem;
  }

  .c-2 {
    margin-top: 3.5rem;
  }

  .newsletter_title {
    font-family: "Noto Serif Display", serif;
    font-size: 2.3rem;
    font-weight: 300;
    text-align: center;
  }

  .c-nav-tool_title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .c-nav-tool_list {
    list-style-type: none;
    padding: 0;
  }

  .c-nav-tool_list li {
    margin-bottom: 0.5rem;
  }

  .c-nav-tool_list a.c-link {
    text-decoration: none;
    color: var(--dark);
    font-weight: 500;
    transition: 0.3s ease-in;
    border-bottom: 1px solid transparent;
    display: inline-flex;
  }

  .c-nav-tool_list a.c-link:hover {
    border-bottom: 1px solid var(--dark);
  }

  .social-icons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    opacity: 70%;
    transition: opacity 1s ease-in-out;
    font-size: 12px;
    color: #b61a1a;
  }

  .footer_section_links {
    justify-content: center;
    color: rgba(126, 14, 14, 0.699);
    font-size: 12px;
  }

  .footer_copyright {
    color: var(--light);
    text-align: center;
    padding: 1rem 0;
  }
}

/* Responsive design adjustments */
@media only screen and (min-width: 1024px) {
  .footer {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    backdrop-filter: blur(10px); /* Blur effect */
  }

  .container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--dark);
    position: relative;
  }

  input {
    padding: 0.75rem 0;
    border: none;
    background: none;
    font-weight: 500;
    transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 0;
    width: 100%;
    font-size: 1.05rem;
    font-weight: bolder;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: var(--dark);
  }

  button {
    appearance: none;
    border: 0;
    background: transparent;
  }

  .footer_inner {
    background: var(--light);
    backdrop-filter: blur(50px);
    border: 0.1px solid rgba(233, 232, 232, 0.208);
    border-radius: 5px;
    padding: 2rem;
    margin: 1rem 0;
  }

  .layout {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 2rem;
  }

  .layout_item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .w-50 {
    width: 50%;
  }

  .w-25 {
    width: 25%;
  }

  svg {
    margin: 0.5rem;
  }

  .c-2 {
    margin-top: 3.5rem;
  }

  .newsletter_title {
    font-family: "Noto Serif Display", serif;
    font-size: 2.3rem;
    font-weight: 300;
    text-align: center;
  }

  .c-nav-tool_title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .c-nav-tool_list {
    list-style-type: none;
    padding: 0;
  }

  .c-nav-tool_list li {
    margin-bottom: 0.5rem;
  }

  .c-nav-tool_list a.c-link {
    text-decoration: none;
    color: var(--dark);
    font-weight: 500;
    transition: 0.3s ease-in;
    border-bottom: 1px solid transparent;
    display: inline-flex;
  }

  .c-nav-tool_list a.c-link:hover {
    border-bottom: 1px solid var(--dark);
  }

  .social-icons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    opacity: 70%;
    transition: opacity 1s ease-in-out;
    font-size: 12px;
    color: #b61a1a;
  }

  .footer_section_links {
    justify-content: center;
    color: rgba(126, 14, 14, 0.699);
    font-size: 12px;
  }

  .footer_copyright {
    color: var(--light);
    text-align: center;
    padding: 1rem 0;
  }
}

/*Product adjustment */

.product-overlay {
  position: relative; /* Position the overlay relative to its children */
  width: 100%; /* Ensure the overlay takes up the full width of its parent */
}

.product-image {
  width: 100%; /* Ensure the image takes up the full width of its parent */
  display: block;
}

.product-details {
  color: rgb(20, 14, 14); /* Ensure the text is visible against the image */
  text-align: center; /* Center-align text */
  width: 100%; /* Ensure the details take up the full width of its parent */
}

.product-details .details {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  gap: 10px; /* Space between items */
}

.product-details .details .price {
  font-size: 24px; /* Increase font size for visibility */
  font-weight: bold; /* Make the price stand out */
}

.product-details button {
  background-color: #5c494988; /* Green */
  border-radius: 15px;
  border: 2px solid #423d3e;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-top: 20px; /* Add some space above the button */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .gallery {
    width: 100%;
  }
}

.ProductDetails {
  max-width: 100%;
  font-size: 20px;
  text-align: center;
  background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
  padding: 5%;
}

.ProductDetails img {
  position: relative;
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
}

.ProductDetails h3 {
  font-size: 40px;
  color: rgba(73, 49, 49, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}

.ProductDetails p {
  font-size: 20px;
  color: rgba(163, 45, 15, 0.925);
  padding-left: 13%;
  padding-right: 13%;
}

.ProductDetails button {
  border: none;
  color: rgb(189, 25, 25);
  padding: 16px 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: 2px solid #04aa6d;
  background-color: #0704aa85;
  border-radius: 5px;
}

@media only screen and (min-width: 600px) {
  .ProductDetails {
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
    padding: 5%;
  }

  .ProductDetails img {
    position: relative;
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .ProductDetails h3 {
    font-size: 40px;
    color: rgba(73, 49, 49, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }

  .ProductDetails p {
    font-size: 20px;
    color: rgba(163, 45, 15, 0.925);
    padding-left: 13%;
    padding-right: 13%;
  }

  .ProductDetails button {
    border: none;
    color: rgb(189, 25, 25);
    padding: 16px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border: 2px solid #04aa6d;
    background-color: #0704aa85;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .ProductDetails {
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
    padding: 5%;
  }

  .ProductDetails img {
    position: relative;
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .ProductDetails h3 {
    font-size: 40px;
    color: rgba(73, 49, 49, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }

  .ProductDetails p {
    font-size: 20px;
    color: rgba(163, 45, 15, 0.925);
    padding-left: 13%;
    padding-right: 13%;
  }

  .ProductDetails button {
    border: none;
    color: rgb(189, 25, 25);
    padding: 16px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border: 2px solid #04aa6d;
    background-color: #0704aa85;
    border-radius: 5px;
  }
}

/*About Page*/

.about-container {
  /* Styles for the container holding the about content */
  padding: 20px;
  color: #fff; /* Assuming you want white text for readability */
  max-width: 800px; /* Limit the width for better readability */
  margin: 0 auto; /* Center the container */
  backdrop-filter: blur(5px); /* Blur effect */

}

.about-heading {
  /* Styles for the headings */
  font-size: 2em;
  margin-bottom: 10px;
}

.about-text {
  /* Styles for the text content */
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.map-container {
  /* Styles for the map container */
  margin-top: 20px;
  width: 100%;
  height: 450px;
}

.map-container iframe {
  /* Styles for the iframe within the map container */
  width: 100%;
  height: 100%;
  border: none;
}

/*About Page 600px*/
@media only screen and (min-width: 600px) {
  .about-background-image {
    /* Styles for the background image container */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
  }

  .about-container {
    /* Styles for the container holding the about content */
    padding: 20px;
    color: #fff; /* Assuming you want white text for readability */
    max-width: 800px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the container */
  }

  .about-heading {
    /* Styles for the headings */
    font-size: 2em;
    margin-bottom: 10px;
  }

  .about-text {
    /* Styles for the text content */
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .map-container {
    /* Styles for the map container */
    margin-top: 20px;
    width: 100%;
    height: 450px;
  }

  .map-container iframe {
    /* Styles for the iframe within the map container */
    width: 100%;
    height: 100%;
    border: none;
  }
}

/*About Page 1024px*/
@media only screen and (min-width: 1024px) {
  .about-background-image {
    /* Styles for the background image container */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
  }

  .about-container {
    /* Styles for the container holding the about content */
    padding: 20px;
    color: #fff; /* Assuming you want white text for readability */
    max-width: 800px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the container */
  }

  .about-heading {
    /* Styles for the headings */
    font-size: 2em;
    margin-bottom: 10px;
  }

  .about-text {
    /* Styles for the text content */
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .map-container {
    /* Styles for the map container */
    margin-top: 20px;
    width: 100%;
    height: 450px;
  }

  .map-container iframe {
    /* Styles for the iframe within the map container */
    width: 100%;
    height: 100%;
    border: none;
  }
}

/*Contact-container*/

/* Container for the entire contact section */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  backdrop-filter: blur(5px); /* Blur effect */

}

/* Form container */
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

/* Input fields and textarea */
.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit button */
.form-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Reach out and legal stuff containers */
.reach-out-container,
.legal-stuff-container,
.terms-conditions-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  align-items: center;
}

/* Headings */
.contact-container h1,
.reach-out-container h2,
.legal-stuff-container h2,
.terms-conditions-container h3 {
  color: #333;
  margin-bottom: 10px;
}

/* Paragraphs */
.contact-container p,
.reach-out-container p,
.legal-stuff-container p,
.terms-conditions-container p {
  color: #666;
  margin-bottom: 10px;
}

/*Contact Page 600px*/
@media only screen and (min-width: 600px) {
  /* Container for the entire contact section */
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    backdrop-filter: blur(5px); /* Blur effect */
  }

  /* Form container */
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  /* Input fields and textarea */
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  /* Submit button */
  .form-container button {
    padding: 10px 20px;
    background-color: #090f14;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* Reach out and legal stuff containers */
  .reach-out-container,
  .legal-stuff-container,
  .terms-conditions-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  /* Headings */
  .contact-container h1,
  .reach-out-container h2,
  .legal-stuff-container h2,
  .terms-conditions-container h3 {
    color: #333;
    margin-bottom: 10px;
  }

  /* Paragraphs */
  .contact-container p,
  .reach-out-container p,
  .legal-stuff-container p,
  .terms-conditions-container p {
    color: #666;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  /* Container for the entire contact section */
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    backdrop-filter: blur(5px); /* Blur effect */
  }

  /* Form container */
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  /* Input fields and textarea */
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  /* Submit button */
  .form-container button {
    padding: 10px 20px;
    background-color: #101214;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* Reach out and legal stuff containers */
  .reach-out-container,
  .legal-stuff-container,
  .terms-conditions-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  /* Headings */
  .contact-container h1,
  .reach-out-container h2,
  .legal-stuff-container h2,
  .terms-conditions-container h3 {
    color: #d8c6c6;
    margin-bottom: 10px;
  }

  /* Paragraphs */
  .contact-container p,
  .reach-out-container p,
  .legal-stuff-container p,
  .terms-conditions-container p {
    color: #807979;
    margin-bottom: 10px;
  }
}
/* Cart */
.cart-container {
  padding: 1rem 2rem;
  backdrop-filter: blur(5px); /* Blur effect */
}
.cart-container h2 {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}
.cart-container .titles {
  margin: 1rem 0 0.5rem 0;
}
.cart-container .titles h3 {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 0.5rem;
}
.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 0.5rem 0;
}
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-item .cart-product img {
  width: 80px;
  max-width: 100%;
  margin-right: 0;
}
.cart-item .cart-product h3 {
  font-weight: 400;
  text-align: center;
  margin: 0.5rem 0;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.5rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover {
  color: black;
}
.cart-item .cart-product-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
  margin: 0.5rem 0;
}
.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.5rem 0;
}
.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: center;
  font-weight: 500;
}


@media only screen and (min-width: 600px) {
  /* Cart */
.cart-container {
  padding: 2rem 4rem;
  backdrop-filter: blur(5px); /* Blur effect */

}
.cart-container h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
.cart-container .titles {
  margin: 2rem 0 1rem 0;
}
.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}
.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
  display: flex;
}
.cart-item .cart-product img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}
.cart-item .cart-product h3 {
  font-weight: 400;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover {
  color: black;
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}
.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}
.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: right;
  font-weight: 700;
}
}

@media only screen and (min-width: 1024px) {
  /* Cart */
.cart-container {
  padding: 2rem 4rem;
  backdrop-filter: blur(5px); /* Blur effect */

}
.cart-container h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
.cart-container .titles {
  margin: 2rem 0 1rem 0;
}
.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}
.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
  display: flex;
}
.cart-item .cart-product img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}
.cart-item .cart-product h3 {
  font-weight: 400;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover {
  color: black;
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}
.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}
.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: right;
  font-weight: 700;
}
}
/* cart summary */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}
.cart-summary .clear-cart {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #161719;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout .cart-login {
  background-color: rgba(255, 51, 0, 0.736);
  color: rgba(0, 0, 0, 0.769);
}

.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}
.continue-shopping a,
.start-shopping a {
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}
.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoImg {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the logo horizontally */
  padding: 5px; /* Reduced padding for a smaller logo */
  background-color: #b32020; /* Maintain a subtle background for contrast */
  border-radius: 5px; /* Keep rounded corners for a softer look */
  box-shadow: 0 1px 2px rgba(236, 53, 53, 0.05); /* Subtle shadow for depth */
}

.logoImg img {
  max-width: 100%; /* Reduced maximum width for a smaller logo */
  height: auto; /* Maintain aspect ratio */
  margin-right: 5px; /* Reduced margin for a smaller logo */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.logoImg:hover img {
  transform: scale(1.05); /* Slightly enlarge the logo on hover */
}

@media only screen and (min-width: 600px) {
  .logoImg {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the logo horizontally */
    padding: 5px; /* Reduced padding for a smaller logo */
    background-color: #b32020; /* Maintain a subtle background for contrast */
    border-radius: 5px; /* Keep rounded corners for a softer look */
    box-shadow: 0 1px 2px rgba(236, 53, 53, 0.05); /* Subtle shadow for depth */
  }

  .logoImg img {
    max-width: 100%; /* Reduced maximum width for a smaller logo */
    height: auto; /* Maintain aspect ratio */
    margin-right: 5px; /* Reduced margin for a smaller logo */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  .logoImg:hover img {
    transform: scale(1.05); /* Slightly enlarge the logo on hover */
  }
}

@media only screen and (min-width: 1024px) {
  .logoImg {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the logo horizontally */
    padding: 5px; /* Reduced padding for a smaller logo */
    background-color: #b32020; /* Maintain a subtle background for contrast */
    border-radius: 5px; /* Keep rounded corners for a softer look */
    box-shadow: 0 1px 2px rgba(236, 53, 53, 0.05); /* Subtle shadow for depth */
  }

  .logoImg img {
    max-width: 100%; /* Reduced maximum width for a smaller logo */
    height: auto; /* Maintain aspect ratio */
    margin-right: 5px; /* Reduced margin for a smaller logo */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  .logoImg:hover img {
    transform: scale(1.05); /* Slightly enlarge the logo on hover */
  }
}

submit-button {
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


.home-dashboard {

  backdrop-filter: blur(5px); /* Blur effect */
}

.sidebar {

  
  h3 {

  }

  a {


    svg {
   
    }
  }
}

.dashboard-content {

}

@media only screen and (min-width: 600px) {
  .home-dashboard {
    display: flex;
    height: 100vh;
    backdrop-filter: blur(5px); /* Blur effect */
  }
  
  .sidebar {
    border-right: 1px solid gray;
    height: calc(100vh - 70px);
    position: fixed;
    overflow-y: auto;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  
    h3 {
      margin: 0 0 1rem 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 17px;
    }
  
    a {
      text-decoration: none;
      margin-bottom: 1rem;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 700;
  
      svg {
        margin-right: 0.5rem;
        font-size: 18px;
      }
    } 
  }
  
  .dashboard-content {
    margin-left: 200px;
    padding: 2rem 3rem;
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .home-dashboard {
    display: flex;
    height: 100vh;
    backdrop-filter: blur(5px); /* Blur effect */
  }
  
  .sidebar {
    border-right: 1px solid gray;
    height: calc(100vh - 70px);
    position: fixed;
    overflow-y: auto;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  
    h3 {
      margin: 0 0 1rem 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 17px;
    }
  
    a {
      text-decoration: none;
      margin-bottom: 1rem;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 700;
  
      svg {
        margin-right: 0.5rem;
        font-size: 18px;
      }
    } 
  }
  
  .dashboard-content {
    margin-left: 200px;
    padding: 2rem 3rem;
    width: 100%;
  }
}


/* General styles */
/* General styles */
.article-container {
  color: white;
  padding: 20px;
  background-color: #2a2a2a;
}

.articles {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.article-overlay {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-overlay:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.articles-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.article-details {
  margin-top: 10px;
}

.article-content p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.article-details span {
  display: block;
  margin-bottom: 5px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Medium screens */
@media only screen and (min-width: 600px) {
  .articles {
    grid-template-columns: 1fr 1fr;
  }

  .article-overlay {
    padding: 30px;
  }

  .articles-image {
    border-radius: 15px;
  }

  button {
    padding: 12px 24px;
  }
}

/* Large screens */
@media only screen and (min-width: 1024px) {
  .articles {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .article-overlay {
    padding: 40px;
  }

  .articles-image {
    border-radius: 20px;
  }

  button {
    padding: 14px 28px;
  }
}

/*Post-container styling */
.post-container {
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
}

.post-header {
  text-align: center;
  margin-bottom: 20px;
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.post-details {
  margin-top: 20px;
}

.post-details span {
  font-weight: bold;
}

.post-content {
  margin-top: 20px;
  line-height: 1.6;
}

.post-date {
  font-style: italic;
  color: #777;
  margin-top: 10px;
}

.comment-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.comment-button:hover {
  background-color: #0056b3;
}

